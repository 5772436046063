import React from "react";

const Footer = () => {
  return (
    <footer className="  h-[10vh] bg-light w-screen flex items-center flex-col py-2 px-4">
      <p className="text-primaryText">
        All rights resevered © RoLeaks | Terms and services
      </p>
    </footer>
  );
};

export default Footer;
